import {
    REFETCH_DATA,
    CHANGE_PAGE_SIZE_REQUEST,
    GL_ENTRY_ACCOUNTS_LIST_REQUEST,
    GL_ENTRY_ACCOUNTS_LIST_SUCCESS,
    GL_ENTRY_LEDGER_HISTORY_REQUEST,
    GL_ENTRY_LEDGER_HISTORY_SUCCESS,
    GL_ENTRY_LEDGER_ACTION_REQUEST,
    GL_ENTRY_SAVE_NEW_LEDGER_REQUEST,
    GL_ENTRY_SAVE_NEW_LEDGER_SUCCESS,
    GL_ENTRY_CSV_DOWNLOAD_REQUEST,
    GL_ENTRY_CSV_DOWNLOAD_SUCCESS,
    GL_ENTRY_CSV_DOWNLOAD_FAILURE,
    GL_ENTRY_ACCOUNT_SUCCESS,
    ERROR,
} from '../../actions/types';

const initialState = {
    pagination: { items: [] },
    loading: false,
    downloading: false,
    refetchData: false,
    accountsList: [],
    loadingDataTable: false,
    loadingSaver: false,
    error: null,
    account: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case REFETCH_DATA:
            return { ...state, refetchData: true, error: null };
        case CHANGE_PAGE_SIZE_REQUEST:
            return { ...state, loading: true, refetchData: true };
        case GL_ENTRY_ACCOUNTS_LIST_REQUEST:
            return { ...state, accountsList: [], error: null };
        case GL_ENTRY_ACCOUNTS_LIST_SUCCESS:
            return { ...state, accountsList: action.payload?.data || [] };
        case GL_ENTRY_LEDGER_HISTORY_REQUEST:
            return {
                ...state,
                loading: true,
                loadingDataTable: true,
                refetchData: false,
            };
        case GL_ENTRY_LEDGER_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingDataTable: false,
                pagination: {
                    items: action.payload.data,
                    total: action.payload.recordsFiltered,
                },
            };
        case GL_ENTRY_LEDGER_ACTION_REQUEST:
            return { ...state, loadingDataTable: true, error: null };
        case GL_ENTRY_SAVE_NEW_LEDGER_REQUEST:
            return { ...state, loadingSaver: true };
        case GL_ENTRY_SAVE_NEW_LEDGER_SUCCESS:
            return { ...state, loadingSaver: false };
        case GL_ENTRY_CSV_DOWNLOAD_REQUEST:
            return { ...state, downloading: true };
        case GL_ENTRY_CSV_DOWNLOAD_SUCCESS:
            return { ...state, downloading: false };
        case GL_ENTRY_CSV_DOWNLOAD_FAILURE:
            return { ...state, downloading: false };
        case GL_ENTRY_ACCOUNT_SUCCESS:
            return { ...state, account: action.payload?.data?.data };
        case ERROR:
            return {
                ...state,
                loading: false,
                loadingSaver: false
            };
        default:
            return state;
    }
}
